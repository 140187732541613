/**
 * Selectors for verification
 */

import { createSelector } from 'reselect'

import { isIdScanRequired, isUsaIdOnly } from 'redux/location/selectors'
import {
  getPhoneVerifiedStatus,
  isBasicDeclined,
  isBasicIncomplete,
  isBasicExpired,
  getIdCardType,
  hasUsaId,
  ELIGIBILITY_PROOF_TYPES,
  PERSONA_STATUSES,
  getPersonaVerificationStatus,
  getDeclinedReasonIds,
  DECLINE_REASONS
} from 'redux/profile/selectors'

import { RootState } from '../reducers'

export const STEPS = {
  PHONE: 'phone',
  ID: 'id',
  DECLINED: 'declined',
  DONE: 'done'
}

const getVerification = (state: RootState) => state.verification

export const getVerificationProcessErrors = createSelector([getVerification], (verification) => verification.err)

export const getVerificationResendStatus = createSelector(
  [getVerification],
  (verification) => verification.phone.hasPhoneCodeBeenResent
)

export const getVerificationPhoneNumber = createSelector(
  [getVerification],
  (verification) => verification.phone.phoneNumber
)

export const getFlowStep = createSelector(
  [
    getPhoneVerifiedStatus,
    isBasicIncomplete,
    isBasicDeclined,
    isBasicExpired,
    getPersonaVerificationStatus,
    getDeclinedReasonIds
  ],
  (isPhoneVerified, basicIncomplete, basicDeclined, basicExpired, personaVerificationStatus, declineReasonIds) => {
    if (!isPhoneVerified) {
      return STEPS.PHONE
    } else if (
      personaVerificationStatus === PERSONA_STATUSES.REJECT ||
      declineReasonIds.includes(DECLINE_REASONS.STATE_ID_DUPLICATED)
    ) {
      return STEPS.DECLINED
    } else if (basicIncomplete || basicExpired || basicDeclined) {
      return STEPS.ID
    } else {
      return STEPS.DONE
    }
  }
)

export const getPageId = createSelector([getVerification], (verification) => verification.currentPageId)

export const isIdScanApproved = createSelector(
  [getIdCardType, isIdScanRequired],
  (cardType, idScanReq) => !idScanReq || !(cardType === ELIGIBILITY_PROOF_TYPES.PASSPORT)
)

export const isIdOriginApproved = createSelector(
  [hasUsaId, isUsaIdOnly],
  (isFromUsa, isUsaOnly) => !isUsaOnly || isFromUsa
)

export const isIdValidInArea = createSelector(
  [isIdScanApproved, isIdOriginApproved],
  (idScanApproved, idOriginApproved) => idScanApproved && idOriginApproved
)

export const getPersonaInquiryId = createSelector([getVerification], (verification) => {
  return verification.persona.inquiryId || ''
})

export const getPersonaSessionToken = createSelector([getVerification], (verification) => {
  return verification.persona.sessionToken || ''
})
