/**
 * this should be the only place we require '@eaze/eaze-api-client' and construct it.
 * This avoids a lot of duplicate code having the construct the API object in every file that uses
 * it. This should also make a it slightly easier to mock :)
 */

import client from '@eaze/eaze-api-client'

import { getApiUrl } from '@helpers/constants'

const url = getApiUrl()

export default client(url).v1

export const apiV2 = client(url).v2
