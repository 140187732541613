/**
 * Actions for alerts
 */

import t from './actionTypes'

export function setAlertTypeVisibility(alertType, show = true) {
  const type = show ? `SHOW_${alertType}` : `HIDE_${alertType}`
  return { type }
}

export function setCurrentAction(currentAction) {
  return {
    type: t.SET_CURRENT_ACTION,
    currentAction
  }
}

export function resetCurrentAction() {
  return {
    type: t.RESET_CURRENT_ACTION
  }
}
