import { createSelector } from 'reselect'

import { RootState } from '../reducers'

export const getPromo = (state: RootState) => state.promo

export const getPromoCode = createSelector([getPromo], (promo) => promo.code)

export const getRemovedPromo = createSelector([getPromo], (promo) => promo.removed)

export default {
  getPromo,
  getRemovedPromo,
  getPromoCode
}
