const ROUTES = {
  // APP_LINK: '/applink/:app-name', // Commented out on purpose. /app/<client, md, driver> are used to deeplink to a specific app.
  // No need to handle these routes in www but it's useful to list the route here in order to reserve this route for deep linking
  APPLY_PROMO: '/apply-promo',
  ARTICLE: '/article/[slug]',
  BLOG: '/blog',
  BRAND: '/brand',
  CART: '/cart',
  CATEGORY: '/category',
  CATEGORY_MENU_PAGE: '/category/[menuSlug]',
  CATEGORY_GROUP_PAGE: '/category/[menuSlug]/[groupSlug]',
  CHECKOUT: '/checkout',
  CITY_PAGE: '/weed-delivery/[slug]',
  CONCIERGE: '/concierge/[slug]',
  DELIVERYAREA: '/weed-delivery',
  WEED_DELIVERY: '/weed-delivery',
  EMOJI_FIRE: '/🔥',
  FIRE: '/fire',
  GROUP: '/groups', // used for getCurrentPath as we want to use pathname
  GROUPS: '/groups/[groupSlug]',
  INVITE: '/invite',
  LOGIN: '/login',
  MENU: '/menu',
  MENU_SLUG: '/menu/:slug',
  ORDER_STATUS: '/orders/[id]',
  PRESS: '/press',
  PROFILE: '/profile',
  PROMO_CODE: '/invite/:promoCode',
  RESET: '/reset/[id]',
  RESET_PASSWORD: '/reset-password',
  ROBOTS: '/robots.txt',
  ROOT: '/',
  SERVICEMAP: '/servicemap.json',
  SHARE: '/share/:shareMethod/:inviteCode',
  SHOW_ORDER: '/orders/[id]',
  SIGNUP: '/signup',
  SSO: '/sso',
  VERIFY: '/verify',
  VERIFY_IDENTITY: '/verify/identity',
  VERIFY_PHONE: '/verify/phone',
  VERIFY_RECCOMENDATION: '/verify/recommendation',
  VERIFY_TOKEN: '/verify/phone/:token'
} as const
export type RouteNames = keyof typeof ROUTES
export type Routes = typeof ROUTES[RouteNames]

export const BASE_ROUTES: Routes[] = [
  ROUTES.ROOT,
  ROUTES.ARTICLE,
  ROUTES.BLOG,
  ROUTES.WEED_DELIVERY,
  ROUTES.CITY_PAGE,
  ROUTES.MENU,
  ROUTES.GROUP,
  ROUTES.GROUPS,
  ROUTES.CATEGORY_MENU_PAGE,
  ROUTES.CATEGORY_GROUP_PAGE
]
export const SHOPPING_ROUTES: Routes[] = [
  ROUTES.MENU,
  ROUTES.GROUPS,
  ROUTES.CATEGORY_MENU_PAGE,
  ROUTES.CATEGORY_GROUP_PAGE
]
export const SHOPPING_PATHS: Routes[] = [ROUTES.MENU, ROUTES.GROUP, ROUTES.CATEGORY]
export const DYNAMIC_SHOPPING_ROUTES: Routes[] = [
  ROUTES.GROUPS,
  ROUTES.ARTICLE,
  ROUTES.CITY_PAGE,
  ROUTES.CATEGORY_MENU_PAGE,
  ROUTES.CATEGORY_GROUP_PAGE
]

export default ROUTES
