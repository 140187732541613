import qs from 'qs'

import { ClientSideResponse } from '@bff/@types/types'
import { ErrorResponse } from '@bff/models/bff/menu'
import { Product } from '@bff/models/og/menu'
import { NEXTJS_API_PATH, WWW_BASE_URL } from '@bff/utils/constants'

import { ProductRequest } from './logic'

/**
 * Components will use this function make request to /api/v2/products/:productId?menuSlug=default&placeId=abc123
 * api handler uses query params to check redis cache
 * if cache miss, make request to pine's /api/products?productId=:productId
 */
export default async function getClientSideProduct({
  productId,
  placeId,
  menuSlug
}: ProductRequest): ClientSideResponse<Product> {
  let result = null
  let statusCode: number = null
  let URL = `${WWW_BASE_URL}${NEXTJS_API_PATH}/products/${productId}`

  if (placeId || menuSlug) {
    const params = qs.stringify(
      {
        placeId,
        menuSlug
      },
      { encode: true }
    )
    URL += `?${params}`
  }

  try {
    const response = await fetch(URL)
    result = await response.json()
    statusCode = response.status
    if (!response.ok) {
      const errorResult = result as ErrorResponse
      throw Error(errorResult.message)
    }
  } catch (err) {
    console.error(`Error fetching product with ID ${productId}`, err)
    return { err: { message: result?.message, statusCode } as ErrorResponse, data: null }
  }
  return { err: null, data: result }
}
