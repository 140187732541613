import window from 'global/window'

import errorHandler from 'error-handler'

/**
 * Simple callback to use as a default arugment that just returns the result or throws an error.
 * Used with functions that might need to poll or pull from API once.
 */
export function defaultCallback(err?: Error, res?: Response) {
  if (err) return errorHandler(err)

  return res
}

export function requestCallback(callback: () => void) {
  if (!callback) {
    console.warn('callback for request callback must be defined')
    return
  }
  if (window.requestIdleCallback) {
    return window.requestIdleCallback(callback) as IdleRequestCallback
  } else {
    return setTimeout(callback)
  }
}
