import t from './actionTypes'

export function requestOrderStatus() {
  return {
    type: t.REQUEST_ORDER_STATUS
  }
}

export function receiveOrderStatus() {
  return {
    type: t.RECEIVE_ORDER_STATUS
  }
}

export function setOrderStatusData(orderStatusData) {
  return {
    type: t.SET_ORDER_STATUS_DATA,
    payload: orderStatusData
  }
}

export function resetOrderStatus() {
  return {
    type: t.RESET_ORDER_STATUS
  }
}

export function setHasBeenFirstInQueue() {
  return {
    type: t.SET_HAS_BEEN_FIRST_IN_QUEUE
  }
}

export function setOrderStatusTrackerData(orderStatusTrackerData) {
  return {
    type: t.SET_ORDER_STATUS_TRACKER_DATA,
    payload: orderStatusTrackerData
  }
}

export default {
  requestOrderStatus,
  receiveOrderStatus,
  setOrderStatusData,
  resetOrderStatus,
  setHasBeenFirstInQueue,
  setOrderStatusTrackerData
}
