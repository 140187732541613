export const calculateMinutesUntil = (isoTimeString: string) => {
  const timeInMilliseconds = Date.parse(isoTimeString) - Date.now()
  const timeInSeconds = timeInMilliseconds / 1000
  const timeInMinutes = Math.round(timeInSeconds / 60)
  return timeInMinutes
}

export const convertStringToIso = (timeString: string) => {
  if (isTimeStringIso(timeString)) {
    return timeString
  } else return `${timeString}Z`
}

export const isTimeStringIso = (timeString = '') => {
  const indexOfLastLetter = timeString.length - 1
  const lastLetter = timeString[indexOfLastLetter] || ''
  return Boolean(lastLetter.toLowerCase() === 'z')
}

export const convertTimeStringToMinutesUntil = (timeString: string) => {
  const isoString = convertStringToIso(timeString)
  return calculateMinutesUntil(isoString)
}
