/**
 * Selectors for auth store
 */

import { createSelector } from 'reselect'

import { RootState } from '../reducers'

const DEFAULT_ERROR_MESSAGE = 'Something went wrong'

/**
 * Get the auth object from state
 */
export const getAuth = (state: RootState) => state.auth

// since we're no longer storing xAuthToken, id or email in the auth store .. let's also use a getUser
// function that fetches state.user
export const getUser = (state: RootState) => state.user

/**
 * Get errors related to auth
 */
export const getAuthError = createSelector([getAuth], (auth) => auth.err)

/**
 * Get the user id
 */
export const getUserId = createSelector([getUser], (user) => user.userId)

export const getToken = createSelector([getUser], (user) => user.xAuthToken)

/**
 * Return true if the user is logged in
 */
export const isLoggedIn = createSelector([getToken], (token) => !!token)

/**
 * Get and compose the error object from auth or an empty error
 */
export const getError = createSelector([getAuth], (auth) => {
  if (!auth.err) {
    // Eaze error component requires to always pass an error object with type and text
    // todo:marco fix this
    return {
      type: 'INFO',
      text: null
    }
  } else {
    return {
      type: 'ERROR',
      text: auth.err || DEFAULT_ERROR_MESSAGE
    }
  }
})

/**
 * Get the user's email
 */
export const getUserEmail = createSelector([getUser], (user) => user.email)

/**
 * Get the user's zip code
 */
export const getUserZipcode = createSelector([getUser], (user) => user.zipcode)

export const getUserPhoneNumber = createSelector([getUser], (user) => user.mobilePhone)

/**
 * Get errors related to an invalid login attempt
 */
export const getInvalidLoginAttempt = createSelector([getAuth], (auth) => auth.invalidLoginAttempt)

/**
 * Get errors related to an invalid password recovery attempt
 */
export const getInvalidPasswordRecoveryAttempt = createSelector(
  [getAuth],
  (auth) => auth.invalidPasswordRecoveryAttempt
)

/**
 * Return if a user is an employee.
 * We define an employee as a user that has a @eaze.com account.
 * Don't rely on this for critical features, since we don't verify email addresses, so we can't guarantee that a user changes his email address to @eaze.com
 * We currently use this selector for feature rollout
 */
export const isEmployee = createSelector([getUserEmail], (email) => {
  // `> 0` since the `@` can't be the first char of a email address
  return Boolean(
    email && (email.includes('@eaze.com') || email.includes('@eazeup.com') || email.includes('@stachs.co'))
  )
})

export const getPasswordRecoveryIdentifier = createSelector([getAuth], (auth) => auth.passwordRecoveryIdentifier)

export const getPhoneConfirmError = createSelector([getAuth], (auth) => auth.phoneConfirmError)

export const getProfileChangeAttempt = createSelector([getAuth], (auth) => Boolean(auth.attemptProfileChange))
