export default {
  94102: '94102',
  94104: '94104',
  94103: '94103',
  94105: '94105',
  94108: '94108',
  94107: '94107',
  94110: '94110',
  94109: '94109',
  94112: '94112',
  94111: '94111',
  94115: '94115',
  94114: '94114',
  94117: '94117',
  94116: '94116',
  94118: '94118',
  94121: '94121',
  94123: '94123',
  94122: '94122',
  94124: '94124',
  94127: '94127',
  94126: '94126',
  94129: '94129',
  94131: '94131',
  94133: '94133',
  94132: '94132',
  94134: '94134',
  94139: '94139',
  94143: '94143',
  94151: '94151',
  94159: '94159',
  94158: '94158',
  94188: '94188',
  94177: '94177',
  Alameda: 'Alameda',
  Atherton: 'Atherton',
  Bell: 'Bell',
  Belmont: 'Belmont',
  Berkeley: 'Berkeley',
  'Beverly Hills': 'Beverly Hills',
  Brisbane: 'Brisbane',
  'Buena Park': 'Buena Park',
  Burlingame: 'Burlingame',
  Campbell: 'Campbell',
  Carson: 'Carson',
  'Chula Vista': 'Chula Vista',
  Coronoda: 'Coronoda',
  'Costa Mesa': 'Costa Mesa',
  'Culver City': 'Culver City',
  Cypress: 'Cypress',
  'Daly City': 'Daly City',
  Downey: 'Downey',
  'El Camino Village': 'El Camino Village',
  'El Segundo': 'El Segundo',
  'Emerald Hills': 'Emerald Hills',
  Emeryville: 'Emeryville',
  'Florence-Graham': 'Florence-Graham',
  'Foster City': 'Foster City',
  Fullerton: 'Fullerton',
  Hayward: 'Hayward',
  'Hermosa Beach': 'Hermosa Beach',
  'Highlands-Baywood Park': 'Highlands-Baywood Park',
  Hillsborough: 'Hillsborough',
  'La Mirada': 'La Mirada',
  'Laguna Beach': 'Laguna Beach',
  'Laguna Hills': 'Laguna Hills',
  'Laguna Woods': 'Laguna Woods',
  Lennox: 'Lennox',
  'Los Altos Hills': 'Los Altos Hills',
  'Los Altos': 'Los Altos',
  'Los Gatos': 'Los Gatos',
  Loyola: 'Loyola',
  Lynwood: 'Lynwood',
  'Marina del Rey': 'Marina del Rey',
  'Menlo Park': 'Menlo Park',
  Millbrae: 'Millbrae',
  'Monte Sereno': 'Monte Sereno',
  Montebello: 'Montebello',
  'Mount Hamilton': 'Mount Hamilton',
  'Mountain View': 'Mountain View',
  Oakland: 'Oakland',
  Pacifica: 'Pacifica',
  'Palo Alto': 'Palo Alto',
  Piedmont: 'Piedmont',
  Placentia: 'Placentia',
  'Portola Valley': 'Portola Valley',
  'Redondo Beach': 'Redondo Beach',
  'Redwood City': 'Redwood City',
  'San Bruno': 'San Bruno',
  'San Carlos': 'San Carlos',
  'San Diego': 'San Diego',
  'San Francisco': 'San Francisco',
  'San Jose': 'San Jose',
  'San Mateo': 'San Mateo',
  'Santa Ana': 'Santa Ana',
  'Santa Clara': 'Santa Clara',
  'Santa Monica': 'Santa Monica',
  Saratoga: 'Saratoga',
  'South San Francisco': 'South San Francisco',
  Stanford: 'Stanford',
  Sunnyvale: 'Sunnyvale',
  'View Park-Windsor Hills': 'View Park-Windsor Hills',
  'Villa Park': 'Villa Park',
  'Walnut Park': 'Walnut Park',
  'West Hollywood': 'West Hollywood',
  'West Rancho Dominguez': 'West Rancho Dominguez',
  Westmont: 'Westmont',
  Willowbrook: 'Willowbrook',
  Woodside: 'Woodside'
}
