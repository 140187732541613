/**
  we are using our own version of promisify (written by Jack) which won't
  have version issues we keep getting bitten by with the promisify app
*/

const promisify = function (fn) {
  return function (...args) {
    return new Promise(function (resolve, reject) {
      return fn(...args, function (err, res) {
        return err ? reject(err) : resolve(res)
      })
    })
  }
}

export default promisify
