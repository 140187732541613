export const capitalize = (string) => {
  if (!string || string.length < 1) return ''
  return string[0].toUpperCase() + string.toLowerCase().slice(1)
}

// Use this for things like displaying lists of accepted credit card brands in an error message.
export const arrayToTextList = (wordList) => {
  // If for some reason you give me an array with only one element (y tho?)
  if (wordList.length < 2) return capitalize(wordList.join(''))

  return wordList
    .map((word, index) => {
      const brand = capitalize(word)

      if (index === wordList.length - 1) {
        // Add an or before last item
        return 'or ' + brand
      } else if (wordList.length > 2) {
        // Comma separate anything before the last item
        return brand + ', '
      } else {
        // If we made it here, we're dealing with a 2-item array, no comma needed.
        return brand + ' '
      }
    })
    .join('')
}

// Use this to aid in validation tasks with masked input values
// e.g. stuff like 'mm / yy' where the user only enters 'mmyy'.
// This should keep you from having to change code when masked value formats are changed.
export const parseFilteredLength = (input, omitChars = []) => {
  if (!input) return 0
  // useless for objects, but maybe you wanna parse an array. I dunno. You live your life.
  if (typeof input !== 'string') input = input.toString()

  if (omitChars.length) {
    if (typeof omitChars === 'string') {
      omitChars = [omitChars]
    }

    omitChars.forEach((string) => {
      input = input.replace(new RegExp(string, 'g'), '')
    })
  }

  return input.length
}

export const getUppercaseSlug = (string) => {
  if (!string || !string.toUpperCase) return ''
  return string.toUpperCase().replace(/\s/g, '')
}
