import { createSelector } from 'reselect'

import {
  CASH_PAYMENT_METHOD,
  PRESENT_CREDIT_CARD_PAYMENT_METHOD,
  PRESENT_DEBIT_CARD_PAYMENT_METHOD,
  DEBIT_CASH_BACK_PAYMENT_METHOD,
  ACH_PAYMENT_METHOD,
} from 'helpers/payment'
import { getActiveDepot } from 'redux/location/selectors'

const getState = (state) => state

export const getPayments = createSelector([getState], (state) => state.payments)

export const getACH = createSelector([getPayments], (payments) => payments.ach)

export const getActivePaymentId = createSelector([getPayments], (payments) => payments.activePaymentId)

export const getPaymentProviders = createSelector([getPayments], (payments) => payments.paymentProviders)

export const getDriverTip = createSelector([getPayments], (payments) => payments.driverTip)

export const getAchProvider = createSelector([getPaymentProviders], (providers) => providers?.[ACH_PAYMENT_METHOD])

export const getChargeDescriptor = createSelector([getPayments], (payments) => payments.chargeDescriptor)

export const getCurrentDepotPaymentMethods = createSelector([getActiveDepot], (depot) => depot.payment || [])

export const getAllowACH = createSelector(
  [getCurrentDepotPaymentMethods, getPaymentProviders],
  (paymentMethods, paymentProviders) => {
    const depotAllowsAch = paymentMethods.includes('ACH')
    const depotHasAchProvider = !!paymentProviders?.[ACH_PAYMENT_METHOD]
    return depotAllowsAch && depotHasAchProvider
  }
)

export const allowCash = createSelector([getCurrentDepotPaymentMethods], (paymentMethods) =>
  paymentMethods.includes('Cash')
)

export const allowCreditPresent = createSelector([getCurrentDepotPaymentMethods], (paymentMethods) =>
  paymentMethods.includes('CreditPresent')
)

export const allowDebitPresent = createSelector([getCurrentDepotPaymentMethods], (paymentMethods) =>
  paymentMethods.includes('DebitPinPresent')
)

export const allowDebitCashBack = createSelector([getCurrentDepotPaymentMethods], (paymentMethods) =>
  paymentMethods.includes('DebitCashBack')
)

export const isCashOnly = createSelector(
  [getCurrentDepotPaymentMethods],
  (paymentMethods) => paymentMethods.length === 1 && paymentMethods[0] === 'Cash'
)

// We might want to place this in checkout as a UI property
export const getNeedsPaymentMethod = createSelector(
  [
    allowCash,
    getPayments,
    allowCreditPresent,
    allowDebitPresent,
    allowDebitCashBack,
    getAllowACH,
  ],
  (

    allowCash,
    payments,
    allowCreditPresent,
    allowDebitPresent,
    allowDebitCashBack,
    allowsACH,
  ) => {
    const { activePaymentId } = payments
    if (
      (allowCash && activePaymentId === CASH_PAYMENT_METHOD) ||
      (allowCreditPresent && activePaymentId === PRESENT_CREDIT_CARD_PAYMENT_METHOD) ||
      (allowDebitPresent && activePaymentId === PRESENT_DEBIT_CARD_PAYMENT_METHOD) ||
      (allowDebitCashBack && activePaymentId === DEBIT_CASH_BACK_PAYMENT_METHOD) ||
      (allowsACH && activePaymentId === ACH_PAYMENT_METHOD)
    ) {
      return false
    }
    return true
  }
)

export const getHasActivePaymentId = createSelector([getActivePaymentId], (activePaymentId) => {
  // since one of our enums is 0 we need to account for that
  // any other falsey value should mean no payment method is set
  return activePaymentId || activePaymentId === 0
})

export const getDispensaryId = createSelector([getActiveDepot], (activeDepot) => {
  const dispensary = activeDepot.dispensary
  return dispensary ? dispensary.id : null
})
