/**
 * Usage Notes:
 * There are two ways to use this functionality:
 * 1. Provide a full URL to an image, i.e. for images in the `public/static/` directory, we must provide a full URL:
 * 2. Provide a relative path to images in the `images-<cluster_name>` bucket
 *
 *
 * Cloudflare image URL format:
 *   https://ZONE/cdn-cgi/image/OPTIONS/SOURCE-IMAGE-URL
 * Ex full URL format:                    | imgConfig options   | original URL
 *   https://images.eaze.com/cdn-cgi/image/format=auto,width=300/https://www.eaze.com/static/favicon.png
 * Ex relative URL format:                | imgConfig options   | S3 object reference, including prefix
 *   https://images.eaze.com/cdn-cgi/image/format=auto,width=600/products/f500d2fc-859c-4c4c-996b-c3bd2ba25fcf_Neutron_Genetics_Rocket_ShatterKief_Preroll_Menu.jpg?preset=thumb
 */

import getCloudflareImageUrl, { presetImageConfigs } from '@eaze/image-resizing-helper'

import { IMAGE_BASE } from '@helpers/constants' // https://images.eaze.com in production, https://images.<cluster_name>.eaze.dev in clusters

interface ImgConf {
  anim?: boolean
  dpr?: number
  fit?: 'contain' | 'cover' | 'crop' | 'pad' | 'scale-down'
  format?: 'auto' | 'avif' | 'json' | 'webp'
  gravity?: string
  height?: number
  metadata?: 'copyright' | 'keep' | 'none'
  onerror?: string
  quality?: number
  sharpen?: number
  width?: number
}

const generateImageUrl = (imageUrl: string, imageConfig: ImgConf) => {
  if (!imageUrl) return ''
  // use custom config if provided
  if (typeof imageConfig !== 'undefined') {
    return getCloudflareImageUrl(IMAGE_BASE, imageUrl, imageConfig)
  }
  // else default to productThumb
  return getCloudflareImageUrl(IMAGE_BASE, imageUrl, presetImageConfigs.productThumb)
}

export default generateImageUrl
export { generateImageUrl, presetImageConfigs }
