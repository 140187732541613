import { createSelector } from 'reselect'

import { etaInterval, etaIntervalFormatted } from 'redux/misc-selectors/time-range'

import { RootState } from '../reducers'

export const getEta = (state: RootState) => state.eta

export const getEtaError = createSelector([getEta], (eta) => eta.error)

const ONE_SECOND_IN_MILLISECONDS = 1000

const getEstimateTime = createSelector([getEta], (eta) => `${eta.estimatedDeliveryDateTime}Z`)

export const getEstimateTimeFormatted = createSelector([getEstimateTime], (eta) => {
  const etaInMinutes = (Date.parse(eta) - Date.now()) / ONE_SECOND_IN_MILLISECONDS
  const etaIntervalRange = etaInterval(etaInMinutes, false)
  if (isNaN(etaIntervalRange.low)) return '-'
  return etaIntervalFormatted(etaIntervalRange)
})
