import { routeMatcher } from 'route-matcher'

import { Routes } from '@helpers/routes'

import { Alerts, BANNER } from './types'

import config from '.'

function routeChecker(routesToCheck: Routes[], route: Routes) {
  if (!route) return false
  if (!Array.isArray(routesToCheck)) return false
  return routesToCheck.some((possibleRoute) => {
    if (!possibleRoute) return false
    // Next.js likes to use bracket syntax with routing, /post/[slug].  We are using a 3rd party script called routeMatcher
    // that only looks for : & * in urls /post/:id  and /post/*id
    const reformattedRoute = possibleRoute.replace(/\[/g, ':').replace(/\]/g, '')
    return Boolean(routeMatcher(reformattedRoute).parse(route))
  })
}

/**
 * Figure out if the alert type is supposed to show for a given route.
 * @param  {String} type   Either BANNER or MODAL
 * @param  {String} key    A key from ./types
 * @param  {String} route  A route from helpers/routes, like `/menu`
 * @return {Boolean}       True if it should show
 */
export const isVisibleForType = (
  type: 'BANNER' | 'MODAL' = BANNER,
  key: Alerts,
  route: Routes,
  queryParams = {}
): boolean => {
  const lowercaseType = type.toLowerCase()
  const alertConfig = config[key] && config[key][lowercaseType] ? config[key][lowercaseType] : {}
  const { hideIfQueryParams, visibleRoutes } = alertConfig

  if (hideIfQueryParams) {
    const hasQueryParam = hideIfQueryParams.some((queryParamToHideOn: string) => queryParams[queryParamToHideOn])
    if (hasQueryParam) return
  }

  return routeChecker(visibleRoutes, route)
}

/**
 * Figure out if the modal type is supposed to show on load of a specific route.
 * @param  {String} key    A key from ./types
 * @param  {String} route  A route from helpers/routes, like `/menu`
 * @return {Boolean}       True if it should show immediately on route showing
 */
export const showOnLoad = (key: Alerts, route: Routes): boolean => {
  return routeChecker(config[key]?.modal?.showOnLoadRoutes, route)
}
