/**
 * Actions to indicate loading states
 */

import t from './actionTypes'

export function addressLoading() {
  return {
    type: t.ADDRESS_LOADING
  }
}

export function addressLoaded() {
  return {
    type: t.ADDRESS_LOADED
  }
}

export function cancelOrderLoading() {
  return {
    type: t.CANCEL_ORDER_LOADING
  }
}

export function cancelOrderLoaded() {
  return {
    type: t.CANCEL_ORDER_LOADED
  }
}

export function cartLoading() {
  return {
    type: t.CART_LOADING
  }
}

export function cartLoaded() {
  return {
    type: t.CART_LOADED
  }
}

export function creatingSupportTicketLoading() {
  return {
    type: t.CREATING_SUPPORT_TICKET_LOADING
  }
}

export function creatingSupportTicketLoaded() {
  return {
    type: t.CREATING_SUPPORT_TICKET_LOADED
  }
}

export function deliveriesLoading() {
  return {
    type: t.DELIVERIES_LOADING
  }
}

export function deliveriesLoaded() {
  return {
    type: t.DELIVERIES_LOADED
  }
}

export function etaLoading() {
  return {
    type: t.ETA_LOADING
  }
}

export function etaLoaded() {
  return {
    type: t.ETA_LOADED
  }
}

// for specific product groups
export function groupLoading() {
  return {
    type: t.GROUP_LOADING
  }
}

// for specific product groups
export function groupLoaded() {
  return {
    type: t.GROUP_LOADED
  }
}

export function helpCenterArticleLoading() {
  return {
    type: t.HELP_CENTER_ARTICLE_LOADING
  }
}

export function helpCenterArticleLoaded() {
  return {
    type: t.HELP_CENTER_ARTICLE_LOADED
  }
}

export function loginLoading() {
  return {
    type: t.LOGIN_LOADING
  }
}

export function loginLoaded() {
  return {
    type: t.LOGIN_LOADED
  }
}

export function menuProductsLoading() {
  return {
    type: t.MENU_PRODUCTS_LOADING
  }
}

export function menuProductsLoaded() {
  return {
    type: t.MENU_PRODUCTS_LOADED
  }
}

export function mmidImageLoading() {
  return {
    type: t.MMID_IMAGE_LOADING
  }
}

export function mmidImageLoaded() {
  return {
    type: t.MMID_IMAGE_LOADED
  }
}

export function orderCreateLoading() {
  return {
    type: t.ORDER_CREATE_LOADING
  }
}

export function orderCreateLoaded() {
  return {
    type: t.ORDER_CREATE_LOADED
  }
}

export function phoneConfirmLoading() {
  return {
    type: t.PHONE_CONFIRM_LOADING
  }
}

export function phoneConfirmLoaded() {
  return {
    type: t.PHONE_CONFIRM_LOADED
  }
}

export function phoneInputLoading() {
  return {
    type: t.PHONE_INPUT_LOADING
  }
}

export function phoneInputLoaded() {
  return {
    type: t.PHONE_INPUT_LOADED
  }
}

export function productLoading() {
  return {
    type: t.PRODUCT_LOADING
  }
}

export function productLoaded() {
  return {
    type: t.PRODUCT_LOADED
  }
}

export function productsLoading() {
  return {
    type: t.PRODUCTS_LOADING
  }
}

export function productsLoaded() {
  return {
    type: t.PRODUCTS_LOADED
  }
}

export function quoteLoading() {
  return {
    type: t.QUOTE_LOADING
  }
}

export function quoteLoaded() {
  return {
    type: t.QUOTE_LOADED
  }
}

export function resetPasswordLoading() {
  return {
    type: t.RESET_PASSWORD_LOADING
  }
}

export function resetPasswordLoaded() {
  return {
    type: t.RESET_PASSWORD_LOADED
  }
}

export function signupLoading() {
  return {
    type: t.SIGNUP_LOADING
  }
}

export function signupLoaded() {
  return {
    type: t.SIGNUP_LOADED
  }
}

export function stateIDImageLoading() {
  return {
    type: t.STATE_ID_IMAGE_LOADING
  }
}

export function stateIDImageLoaded() {
  return {
    type: t.STATE_ID_IMAGE_LOADED
  }
}

export function stateIdUploading() {
  return {
    type: t.STATE_ID_UPLOADING
  }
}

export function stateIdUploaded() {
  return {
    type: t.STATE_ID_UPLOADED
  }
}

export function taxFeesLoading() {
  return {
    type: t.TAX_FEES_LOADING
  }
}

export function taxFeesLoaded() {
  return {
    type: t.TAX_FEES_LOADED
  }
}

export function userProfileLoading() {
  return {
    type: t.USER_PROFILE_LOADING
  }
}

export function userProfileLoaded() {
  return {
    type: t.USER_PROFILE_LOADED
  }
}

export function userStatusLoading() {
  return {
    type: t.USER_STATUS_LOADING
  }
}

export function userStatusLoaded() {
  return {
    type: t.USER_STATUS_LOADED
  }
}

export function personaLoading() {
  return {
    type: t.PERSONA_LOADING
  }
}

export function personaLoaded() {
  return {
    type: t.PERSONA_LOADED
  }
}
