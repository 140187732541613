import { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { isLoggedIn } from '@/redux/auth/selectors'
import { getEmail, getPhoneNumberBasic, getUserDisplayName } from '@/redux/profile/selectors'

import { isMobile } from './constants'
import errorHandler from './error-handler'

export const ZendeskAPI = (...args) => {
  if (typeof window === 'undefined') {
    console.error('Attempting to use client-side Zendesk API on the server')
    return
  } else if (!window.zE) {
    console.error('Failed to load necessary 3rd-party script: Zendesk chat widget')
    errorHandler(new Error('Failed to load necessary 3rd-party script: Zendesk chat widget'))
    return
  }
  window.zE(...args)
}

export default function ZendeskChat() {
  const displayName = useSelector(getUserDisplayName)
  const email = useSelector(getEmail)
  const mobilePhone = useSelector(getPhoneNumberBasic)
  const userLoggedIn = useSelector(isLoggedIn)

  useEffect(() => {
    // only show the widget on desktop for logged-in users
    if (userLoggedIn && !isMobile) {
      ZendeskAPI('webWidget', 'show')
      if (email) {
        ZendeskAPI('webWidget', 'identify', {
          name: displayName,
          email: email,
          phone: mobilePhone
        })
      }
    } else {
      ZendeskAPI('webWidget', 'hide')
    }

    return () => {
      ZendeskAPI('webWidget', 'hide')
    }
  }, [userLoggedIn, isMobile])

  return null
}
