import { createSelector } from 'reselect'

import { NONE_KEY, TOTAL_EXCEEDED_MINIMUM_KEY } from 'stories/order-price-summary/constants'

import { RootState } from '../reducers'

export const getQuote = (state: RootState) => state.quote

export const getPriceInfo = createSelector([getQuote], (quote) => {
  return quote.priceInfo || {}
})

export const getQuotePromoCredit = createSelector([getPriceInfo], ({ promoCredit }) => {
  return promoCredit
})

export const getKeepTheChangeAmount = createSelector([getPriceInfo], ({ keepTheChange }) => {
  return keepTheChange
})

export const getInviteCreditAmount = createSelector([getPriceInfo], ({ inviteCredit }) => {
  return inviteCredit
})

export const getVeteranDiscountAmount = createSelector([getPriceInfo], ({ veteranDiscount }) => {
  return veteranDiscount
})

export const getSubtotal = createSelector([getPriceInfo], ({ subtotal }) => {
  return subtotal
})

export const getTotalTax = createSelector([getPriceInfo], ({ totalTax }) => {
  return totalTax
})

export const getDeliveryFeeAmount = createSelector([getPriceInfo], ({ deliveryFee }) => {
  return deliveryFee
})

export const getChargeAmount = createSelector([getPriceInfo], ({ chargeAmount }) => {
  return chargeAmount
})

export const getPeakInfo = createSelector([getQuote], (quote) => {
  return quote.peakInfo || {}
})

export const getPeakInfoCurrent = createSelector([getPeakInfo], (peakInfo) => {
  return peakInfo.current || {}
})

export const getLastQuoteTime = createSelector([getQuote], (quote) => {
  return quote.lastQuoteTime
})

export const getDispensaryName = createSelector([getQuote], (quote) => {
  return quote.dispensaryName
})

export const getDepotAdultUseLicense = createSelector([getQuote], (quote) => {
  return quote.depotAdultUseLicense
})

export const getIsDeliveryFeeBypassed = createSelector([getPriceInfo], ({ bypassDeliveryFeeReason, deliveryFee }) => {
  return deliveryFee && bypassDeliveryFeeReason !== NONE_KEY
})

export const getIsDiscountedDeliveryFee = createSelector(
  [getPriceInfo, getPeakInfoCurrent],
  ({ deliveryFee: originalDeliveryFee }, { deliveryFee: currentDeliveryFee }) => {
    return Boolean(typeof currentDeliveryFee === 'number' && currentDeliveryFee < originalDeliveryFee)
  }
)

export const getBypassDeliveryFeeReason = createSelector([getPriceInfo], ({ bypassDeliveryFeeReason }) => {
  return bypassDeliveryFeeReason || ''
})

export const getIsDiscountedDeliveryBypass = createSelector(
  [getPriceInfo, getPeakInfoCurrent],
  (
    { minimumAmountBypassDeliveryFee: originalMinimumAmountBypassDeliveryFee },
    { minimumAmountBypassDeliveryFee: currentMinimumAmountBypassDeliveryFee }
  ) => {
    return Boolean(
      typeof currentMinimumAmountBypassDeliveryFee === 'number' &&
        currentMinimumAmountBypassDeliveryFee < originalMinimumAmountBypassDeliveryFee
    )
  }
)

export const getIsDeliveryPastMinimum = createSelector([getPriceInfo], ({ bypassDeliveryFeeReason }) => {
  return TOTAL_EXCEEDED_MINIMUM_KEY === bypassDeliveryFeeReason
})
