import { MENU_SLUG } from '@helpers/constants'

export const NONE_KEY = 'None'
export const CUSTOMER_FIRST_ORDER_KEY = 'CustomerFirstOrder'
export const TAXES_AND_FEES_BUTTON = 'TaxesAndFeesButton'
export const TOTAL_EXCEEDED_MINIMUM_KEY = 'TotalExceededMinimum'
export const DELIVERY_FEE_KEY = 'deliveryFee'
export const REMAINING_AMOUNT_BYPASS_KEY = 'remainingAmountBypassDeliveryFee'
export const MINIMUM_AMOUNT_BYPASS_KEY = 'minimumAmountBypassDeliveryFee'

export const TEST_IDS = {
  QUOTE_LINE_ITEM_AMOUNT: 'quoteLineItemAmount'
}

export const DISCOUNT_MESSAGE = 'The 25% discount only applies to the first $500 of your order.'

export const mockCart = {
  [MENU_SLUG]: {
    products: [
      { id: 1302, quantity: 1 },
      { id: 1304, quantity: 2 }
    ],
    productsList: [
      {
        type: { id: '1' },
        subtype: { id: '11' },
        prices: [
          { quantity: 1, price: 25, priceType: 2 },
          { quantity: 2, price: 50, priceType: 2 }
        ],
        price: 25,
        id: 1302,
        quantity: 1
      },
      {
        type: { id: '2' },
        subtype: { id: '22' },
        prices: [
          { quantity: 1, price: 30, priceType: 2 },
          { quantity: 2, price: 60, priceType: 2 }
        ],
        price: 300,
        id: 1304,
        quantity: 1
      }
    ],
    id: 1,
    sizeMismatch: false,
    expired: false,
    message: ''
  }
}

export const mockPayments = {
  ach: null,
  activePaymentId: null, // Can be `0` for cash, or an `externalCardId`
  // or 2 for card on delivery or 3 for debit (pin required) on delivery
  chargeDescriptor: '',
  driverTip: 20
}

export const mockPriceInfo = {
  bypassDeliveryFeeReason: 'TotalExceededMinimum',
  cashBack: null,
  chargeAmount: 183.48,
  customerTerminalCharge: null,
  deliveryFee: 0.0,
  driverTip: 0,
  exciseTax: 18,
  inviteCredit: 20,
  keepTheChange: -10,
  minimumAmountBypassDeliveryFee: 50,
  promoCredit: 10,
  remainingAmountBypassDeliveryFee: 0,
  roundUpCharge: 0,
  safetyFee: 3.99,
  subtotal: 625,
  totalPrice: 183.48,
  totalTax: 48.48,
  veteranDiscount: 10
}

export const defaultMockPromo = {
  code: '',
  amount: 10
}

export const mockPromo = {
  code: 'test promo code',
  amount: 10
}

export const mockQuote = { priceInfo: mockPriceInfo }
