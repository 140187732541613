import { createSelector } from 'reselect'

export const isSidebarOpen = createSelector(
  (state) => state.menu,
  (menu) => menu.isSidebarOpen
)

export const getMenuState = createSelector(
  (state) => state.menu,
  (menu) => menu
)

export const getMenuEnabled = createSelector([getMenuState], (reducer) => {
  return reducer.currentMenuEnabled
})

export const getAnnouncementProducts = createSelector([getMenuState], (reducer) => {
  return reducer.announcements
})

export default {
  getMenuState,
  getMenuEnabled,
  getAnnouncementProducts
}
