/**
 * Various functions and constants that are used for cart representation
 * throughout the app
 */

export const CART_MINIMUM = 0

export default {
  CART_MINIMUM
}
