import { ParsedUrlQuery } from 'querystring'

import urlConstructor from '@eaze/url-constructor'
import isEmptyObject from 'is-empty-object'
import qs from 'query-string'

import { DEFAULT_MENU_SLUG } from '@helpers/constants'
import ROUTES from 'helpers/routes'

export type Options = {
  addParams?: object
  asTemplate?: string
  removeParams?: string[]
}

const configObject: Options = {
  addParams: {}, // addParams = {jack: true, otherKey: 'value'}
  asTemplate: '', // /group/:jack?otherKey=:otherKey
  removeParams: [] // ['jack', 'otherKey']
}

/*
  Warning! This WILL NOT match redirectUrls with query params unless you add an entry to 'helpers/routes'
  that expresses each query param as a path variable. See the accompanying test file.
  Highly recommend avoiding usage anywhere the URL is not known/static (ex: `login` action)
*/

// Pass url object in, get href and asPath out
// Can be custom if you're going to change routes: url = { pathname: ROUTES.MENU, query: { jack: true }}
// if you're not changing routes, just pass the router/url context objects in like (getPath(Router), getPath(this.props.router))
// ----
// options object can be any permutation of the above config object
export default function getPath(
  urlObject: { pathname?: string; query?: ParsedUrlQuery; redirectUrl?: string } = {},
  options: Options = {}
): { href: string; asPath: string } {
  // doing the below because passing in the router object in here will mutate the router
  if (isEmptyObject(urlObject)) {
    console.warn('urlObject is required')
    return
  }

  const url = {
    pathname: urlObject.pathname,
    query: { ...urlObject.query },
    redirectUrl: urlObject.redirectUrl
  }

  const config = {
    ...configObject,
    ...options
  }

  if (url.redirectUrl) {
    // redirectUrl should look like <baseUrl>?<queryParams> ex: '/menu?slug=fire&cart=123'
    const redirectSplitArray = url.redirectUrl.split('?')
    url.pathname = redirectSplitArray[0]
    url.query = qs.parse(redirectSplitArray[1])

    if (isEmptyObject(url.query)) {
      // if there are no query params, we want to redirect to the pathname given to us
      // ex: /menu/fire will return /menu/fire for both href and asPath
      return {
        href: url.pathname,
        asPath: url.pathname
      }
    }

    const asTemplate = findTemplate(url)

    if (!asTemplate) {
      console.error('There are no routes matching the redirectUrl', url.redirectUrl)
      return
    }

    if (asTemplate === ROUTES.MENU_SLUG && url.query.slug === DEFAULT_MENU_SLUG) {
      // if we matched menu/:slug and our slug=default then don't redirect to /menu/default, just go to /menu appending all our queryParams
      delete url.redirectUrl
      delete url.query.slug
      url.pathname = ROUTES.MENU
      return getPath(url, {})
    }

    // remove the redirectUrl and call this function again now that we have url.pathname, url.query, and asTemplate
    delete url.redirectUrl
    return getPath(url, { asTemplate })
  }

  if (!url.pathname) {
    console.error('getPath requires the first param to be an object with a pathname key!')
    return
  }

  let route = url.pathname
  const paramsToKeep = url.query || {}

  // if we have config.removeParams, let's loop over each and delete them from our config object
  if (config.removeParams && config.removeParams.length) {
    config.removeParams.forEach((key) => {
      delete paramsToKeep[key]
    })
  }

  // if we have an addParams object, lets loop ove the key values and eventually add them to the href / asPath
  if (!isEmptyObject(config.addParams)) {
    Object.entries(config.addParams).forEach(([key, value]) => {
      paramsToKeep[key] = value
    })
  }

  // if addParams was added, or removeParams didn't contain all of the params, lets re append our paramsToKeep
  if (!isEmptyObject(paramsToKeep)) {
    route = `${route}?${qs.stringify(paramsToKeep)}`
  }

  if (options?.asTemplate) {
    // do some magic with a library that can construct our URL template mapping values with colons
    // TODO NEXTJS: this blows away any queryParams that do NOT match on the route
    const asPath = urlConstructor(options.asTemplate, paramsToKeep)

    return {
      href: route,
      asPath
    }
  }

  return {
    href: route,
    asPath: route
  }
}

// helpers for determining redirectUrl
function findTemplate({ pathname = '', query = {} }) {
  let asPath = ''
  Object.values(ROUTES).some((route) => {
    if (route.indexOf(pathname) === -1) return false

    const re = new RegExp(pathname)
    const routeWithoutBase = route.replace(re, '')
    if (doesRouteMatch(routeWithoutBase, query)) {
      asPath = route
      return true // break out of #some loop as we've found a route template
    }
    return false
  })

  return asPath
}

function doesRouteMatch(url: string, queryParams: ParsedUrlQuery) {
  const splitUrl = url.split('/')

  // loop through any pieces of the url, if a :param does not have a matching queryParam, we return true
  // to break out.  If we break out early that means the route did NOT match, so we return !doesRouteMatch
  let routeMatches = false
  splitUrl.forEach((piece) => {
    if (!piece) return

    // only return true is a queryParam does not match
    if (!doesQueryParamMatch(piece, queryParams)) {
      routeMatches = true
    }
  })

  return routeMatches
}

function doesQueryParamMatch(piece: string, params: ParsedUrlQuery) {
  const key = piece.substring(0)
  const needsValue = key.substring(0, 1) === ':' // determine if we're value or a static string ('value' || ':value')

  if (needsValue) {
    return params[key] !== undefined
  } else {
    // if we don't need a value, return true and move on to next piece
    return true
  }
}
