/**
 * Used in selectors/alert to determine what state the eta response is in.
 */

export default {
  NONE: 0,
  LOCATION: 1,
  CLOSED: 2,
  PRODUCTS_UNAVAILABLE: 3,
  NO_DRIVERS: 4,
  NO_DRIVERS_EXCEPT_CANCELED: 5,
  ALL_DRIVERS_BUSY: 6,
  OVER_ETA: 7,
  INVALID_STREET_ADDRESS: 8
}
