import window from 'global/window'
import qs from 'query-string'

// grab the current path, pathname isn't on window when we're on the server
export const getCurrentPath = (state, props) => {
  const urlObj = props.url || props.router
  if (urlObj) {
    return urlObj.pathname
  } else {
    return window.location && window.location.pathname
  }
}

// grab the current query params
export const getCurrentQueryParams = (state, props) => {
  const urlObj = props.url || props.router
  if (urlObj) {
    return urlObj.query
  } else {
    const windowSearchObj = window.location && window.location.search
    return qs.parse(windowSearchObj)
  }
}
