import { useEffect, useLayoutEffect, useState } from 'react'

export const colorSchemes = {
  light: '(prefers-color-scheme: light)',
  dark: '(prefers-color-scheme: dark)'
}

function onThemeChange(callback: () => void) {
  return (event: MediaQueryListEvent) => {
    if (!event || !event.matches) {
      return
    }

    callback()
  }
}

type Theme = 'light' | 'dark'

const useReactEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

export default function useSystemTheme(initialTheme?: Theme) {
  const [theme, setTheme] = useState(initialTheme || null)

  useEffect(() => {
    // SSR or matchMedia not supported
    if (typeof window === 'undefined' || !window.matchMedia) {
      return
    }

    const lightMatch = window.matchMedia(colorSchemes.light)
    const onLightMatches = onThemeChange(() => setTheme('light'))

    lightMatch.addEventListener('change', onLightMatches)

    const darkMatch = window.matchMedia(colorSchemes.dark)
    const onDarkMatches = onThemeChange(() => setTheme('dark'))

    darkMatch.addEventListener('change', onDarkMatches)

    return () => {
      lightMatch.removeEventListener('change', onLightMatches)
      darkMatch.removeEventListener('change', onDarkMatches)
    }
  }, [])

  useReactEffect(() => {
    // SSR or matchMedia not supported
    if (typeof window === 'undefined' || !window.matchMedia) {
      return
    }

    if (window.matchMedia(colorSchemes.dark).matches && theme !== 'dark') {
      setTheme('dark')
    } else if (window.matchMedia(colorSchemes.light).matches && theme !== 'light') {
      setTheme('light')
    }
  }, [theme])

  return theme
}
