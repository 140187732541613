import t from './actionTypes'

export function setPromo(promoCode: string) {
  return {
    type: t.SET_PROMO,
    payload: promoCode
  }
}

export function resetPromo() {
  return {
    type: t.RESET_PROMO
  }
}

export function requestApplyPromo() {
  return {
    type: t.APPLY_PROMO_SEND
  }
}

export function receiveApplyPromo(payload) {
  return {
    type: t.APPLY_PROMO_RECEIVE,
    payload
  }
}

export function openApplyPromo() {
  return {
    type: t.OPEN_APPLY_PROMO
  }
}

export function toggleRemovedPromo() {
  return {
    type: t.TOGGLE_REMOVED_PROMO
  }
}
